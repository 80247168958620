import React from 'react'

const NavBar = () => {
  return (
    <div className="flex w-full p-1  justify-between items-center  bg-white z-10">
      <div className=" flex items-center gap-5">
        <div className="w-[130px] h-[70px]">
          <img src="/assets/logo/life_plus_logo.png" alt="life_plus_logo" />
        </div>
      </div>
    </div>
  );
}

export default NavBar