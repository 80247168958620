import React, { useContext, useEffect, useState } from "react";
import {
  BiMicrophone,
  BiMicrophoneOff,
  BiVideo,
  BiVideoOff,
} from "react-icons/bi";
import { MdOutlineScreenShare } from "react-icons/md";
import { SocketContext } from "../../context";

const VideoCall = () => {
  const [muteMic, setMuteMic] = useState(true);
  const [muteCamera, setMuteCamera] = useState(true);
  const {
    name,
    callAccepted,
    myVideo,
    answerCall,
    userVideo,
    leaveCall,
    stream,
    call,
  } = useContext(SocketContext);
  const username = name || "user";
  const [shareScreen, setShareScreen] = useState(false);

  useEffect(() => {
    userVideo.current.srcObject = call.stream;
    myVideo.current.srcObject = stream;
  }, [call.stream, myVideo, stream, userVideo]);

  useEffect(() => {
    if (stream) {
      stream.getAudioTracks().forEach((track) => {
        track.enabled = !muteMic;
      });
    }
  }, [muteMic, stream]);

  useEffect(() => {
    if (stream) {
      stream.getVideoTracks().forEach((track) => {
        track.enabled = !muteCamera;
      });
    }
  }, [muteCamera, stream]);

  return (
    <div className="w-full h-full flex flex-col ">
      {call.isReceivingCall && !callAccepted && (
        <div display="flex" justifyContent="space-around" mb="20">
          <button
            variant="outline"
            onClick={answerCall}
            border="1px"
            borderStyle="solid"
            borderColor="black"
          >
            Answer Call
          </button>
        </div>
      )}
      <div className="w-full h-full rounded-md overflow-hidden">
        <div className="w-full h-full rounded-md overflow-hidden">
          <div className="w-full h-full relative rounded-md ">
            <video
              className="w-full h-full object-cover rounded-md"
              ref={userVideo}
              style={{ transform: "scaleX(-1)" }}
              autoPlay
            />
            <div className="bg-primary bg-opacity-70 flex w-fit h-fit rounded-full py-1 px-2 text-[11px] text-white absolute bottom-3 left-3 ">
              {username.length > 10 ? username.slice(0, 10) + ".." : username}
            </div>
            {!call.stream && (
              <div
                className={` w-fit h-fit  items-center p-1 text-white absolute bottom-3 left-14 bg-danger_color rounded-full`}
              >
                <BiMicrophoneOff fontSize={"16"} />
              </div>
            )}
            <div className="w-[200px] h-[150px] md:w-[270px] md:h-[170px] rounded-md overflow-hidden border absolute bottom-3 right-3 text-white ">
              <div className="max-w-full max-h-full rounded-md">
                <video
                  ref={myVideo}
                  autoPlay
                  className="w-full h-full object-cover rounded-md"
                  style={{ transform: "scaleX(-1)" }}
                />
              </div>
              <div className="bg-primary  bg-opacity-70 flex w-fit h-fit rounded-full py-1 px-2 text-[11px] absolute bottom-3 left-3 ">
                {"You"}
              </div>
              {muteMic && (
                <div
                  className={` w-fit h-fit  items-center p-1 absolute bottom-3 left-14 bg-danger_color rounded-full`}
                >
                  <BiMicrophoneOff fontSize={"16"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-fit">
        <div className="w-full h-[70px] flex text-white px-5">
          <div className="flex w-full h-full justify-center items-center gap-x-5">
            <button
              className={`flex items-center p-2.5  ${
                !muteMic ? "bg-primary" : "bg-danger_color"
              } rounded-full`}
              onClick={() => setMuteMic(!muteMic)}
            >
              {!muteMic ? (
                <BiMicrophone fontSize={"21"} />
              ) : (
                <BiMicrophoneOff fontSize={"21"} />
              )}
            </button>
            <button
              className={`flex items-center p-2.5  ${
                !muteCamera ? "bg-primary" : "bg-danger_color"
              } rounded-full`}
              onClick={() => setMuteCamera(!muteCamera)}
            >
              {!muteCamera ? (
                <BiVideo fontSize={"21"} />
              ) : (
                <BiVideoOff fontSize={"21"} />
              )}
            </button>
            <button
              className={`flex items-center p-2.5 bg-primary ${
                shareScreen && "bg-opacity-70"
              } rounded-full`}
              onClick={() => setShareScreen(!shareScreen)}
            >
              <MdOutlineScreenShare fontSize={"21"} />
            </button>
          </div>
          <div className="flex flex-shrink-0  h-full justify-center items-center">
            <button
              onClick={leaveCall}
              className="bg-danger_color  active:bg-red-500 text-white px-4 py-3 text-[12px] rounded-full"
            >
              End Call
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
