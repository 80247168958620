import React, { useState, useContext } from 'react';
import { MdKeyboard } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../context';
const JoinPageMainSection = () => {
  const navigate = useNavigate();
  const { me, callAccepted, callEnded, leaveCall, callUser } =
    useContext(SocketContext);

  const [focus, setFocus] = useState(false);
  const [link, setLink] = useState('');
  const isLink = () => link.length;
  const handleJoin = () => {
    callUser(link);
    if (!isLink()) return;
    navigate(`/room/${link}`);
  };
  return (
    <div className='flex w-full h-full'>
      <div className='flex w-full h-full flex-col items-center justify-center gap-y-8'>
        <div className='flex text-4xl font-medium px-10'>
          Elevate Your Care with Free Video Consultations for Everyone.
        </div>
        <div className='flex text-[14px] font-medium px-10 text-gray-500'>
          We've adapted our secure healthcare platform to provide accessible
          video consultations for all patients. Virtual Wellness Anywhere.
        </div>
        <div className='flex flex-col  w-fit h-fit mt-2 px-2 sm:px-0 gap-y-1 '>
          <div className='flex gap-x-3'>
            <div
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              className={`flex w-full sm:w-[260px] h-[50px] border ${
                focus ? 'border-blue-600 border-2' : 'border-gray-500'
              } rounded-md items-center px-3 gap-x-4`}
            >
              <MdKeyboard className='w-7 h-7' color='gray' />
              <input
                onChange={(e) => setLink(e.target.value)}
                className='w-full h-full border-none outline-none'
                placeholder='Enter link'
              />
            </div>
            <button
              onClick={handleJoin}
              className={`${
                isLink() ? 'text-blue-600' : 'text-gray-400'
              } text-base outline-none border-none`}
            >
              Join
            </button>
          </div>
          <div className='flex w-fit h-fit'>
            <button
              onClick={() => {
                isLink() && navigator.clipboard.writeText(me);
              }}
              className={`${'bg-primary'} text-sm px-2 py-1  text-white rounded-md flex`}
            >
              copy
            </button>
            {callAccepted && !callEnded ? (
              <button
                onClick={leaveCall}
                className={`${
                  isLink() ? 'bg-primary' : 'bg-gray-300'
                } text-sm px-2 py-1  text-white rounded-md flex`}
              >
                Hang up
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className='h-full w-0.5 py-10 hidden md:flex'>
        <div className='h-full w-full bg-gray-300'></div>
      </div>
      <div className='w-full h-full justify-center items-center hidden md:flex flex-col gap-y-5 pb-20'>
        <img src='/assets/images/life_plus_login.png' alt='life_plus_logo' />
        <div className='flex text-base text-gray-500 text-[15px]'>
          Connected Health, Anytime, Anywhere
        </div>
      </div>
    </div>
  );
};

export default JoinPageMainSection;
