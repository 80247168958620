import React from 'react';
import { RiAttachment2 } from "react-icons/ri";
import { IoIosSend } from "react-icons/io";
import Chats from './Chats';

const SideChat = () => {
  const sendMessage = (e) =>{
    e.preventDefault();
  }
  return (
    <>
      <div className="flex w-full h-full rounded-md overflow-y-scroll flex-col py-2 px-2 gap-2 text-gray-500 border">
        <Chats />
      </div>
      <div className="w-full h-fit">
        <div className="w-full h-[70px]">
          <form onSubmit={(e)=>sendMessage(e)} className="flex w-full h-full justify-center items-center gap-1">
            <button
              type="button"
              className="flex p-2 h-fit w-fit items-center "
            >
              <RiAttachment2 fontSize={"23"} color="gray" />
            </button>
            <input
              className="flex w-full h-fit p-2 border rounded-xl outline-none "
              type="text"
            />
            <button
              type="submit"
              className=" h-fit w-fit p-2 flex items-center rounded-xl bg-primary text-white"
            >
              <IoIosSend fontSize={"23"} />
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SideChat
