import React from "react";
import { SideChat, VideoCall } from "../../components/RoomPageComponents";

const Room = () => {
  return (
    <div className="w-full h-full px-2 py-2 sm:px-5">
      <div className="w-full h-full grid grid-cols-1 gap-x-2 lg:grid-cols-4 ">
        <div className="w-full h-full col-span-1 lg:col-span-3 overflow-hidden">
          <VideoCall/>
        </div>
        <div className="hidden lg:flex col-span-1 w-full h-full flex-col rounded-md overflow-hidden">
          <SideChat />
        </div>
      </div>
      <div className="flex lg:hidden w-full h-full flex-col mt-5 rounded-md overflow-hidden">
        <SideChat/>
      </div>
    </div>
  );
};

export default Room;
