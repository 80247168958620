import { Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import { Join, Room } from './pages';
import * as process from 'process';

function App() {
  window.global = window;
  window.process = process;
  window.Buffer = [];
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index={true} element={<Join />} />
        <Route path={`/room/:id`} element={<Room />} />
      </Route>
    </Routes>
  );
}

export default App;
